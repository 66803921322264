import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyAYdgON6tuesjbrTI_P4gkOsxDcpQDj1Ag",
    authDomain: "roadtrip-96db2.firebaseapp.com",
    projectId: "roadtrip-96db2",
    storageBucket: "roadtrip-96db2.appspot.com",
    messagingSenderId: "60588799761",
    appId: "1:60588799761:web:eb57802e33e6a75643beb0",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const provider = new GoogleAuthProvider();
const auth = getAuth();

const storage = getStorage();

export { app, db, storage, provider, auth };
