import { useEffect, useState } from "react";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../firebase";
import ModalImage from "react-modal-image";
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import useGetPhotos from "../hooks/useGetPhotos";
import DeletePhoto from "../components/DeletePhoto.js";
import UpdatePhotoComment from "../components/UpdatePhotoComment.js";

const Photos = ({ admin }) => {
    const [videos, setVideos] = useState([]);
    const [city, setCity] = useState("All");
    const [lastVisible, setLastVisible] = useState(false);
    const [updateCounter, setUpdateCounter] = useState(1);

    // const [photoComment, setPhotoComment] = useState("");
    let [photos, snap] = useGetPhotos(city, lastVisible, updateCounter);

    const cities = [
        "All",
        "Before the Start",
        "Los Angeles",
        "Irvine",
        "San Diego",
        "Lake Havasu City",
        "Flagstaff",
        "Gallup",
        "Santa Fe",
        "Amarillo",
        "Dallas",
        "Memphis",
        "Nashville",
        "Louisville",
        "Indianapolis",
        "Detroit",
        "Toronto",
        "Niagara Falls",
        "Carlisle",
        "Columbia-Bethesda",
        "Washington, DC",
        "Philadelphia",
        "New York",
        "Baltimore",
        "Poundbury",
        "London",
        "France",
        "Paris",
        "Milan",
        "Rome",
    ];

    const handleCityChange = (e) => {
        setCity(e.target.value);
        setLastVisible(false);
        if (!admin) {
            window.scrollTo(0, 0);
        }
    };

    const getVideos = async () => {
        const listRef = ref(storage, "videos");

        // Find all the prefixes and items.
        listAll(listRef)
            .then((res) => {
                let newVideos = [];
                res.items.forEach((itemRef) => {
                    getDownloadURL(itemRef).then((url) => {
                        newVideos = [...newVideos, url];
                        setVideos(newVideos);
                    });
                });
            })
            .catch((error) => {
                // Uh-oh, an error occurred!
            });
    };

    const loadMorePhotos = () => {
        setLastVisible(snap);
        window.scrollTo(0, 0);
    };

    return (
        <Container maxWidth="xl">
            <div className="photos">
                <Typography variant="h2" sx={{ margin: "2rem" }}>
                    Latest Photos
                </Typography>
                <Box
                    sx={{ minWidth: 120, display: { xs: "flex", md: "none" } }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="city-label">City</InputLabel>
                        <Select
                            labelId="city-label"
                            id="city-dropdown"
                            value={city}
                            label="City"
                            onChange={handleCityChange}
                        >
                            {cities.map((city) => {
                                return (
                                    <MenuItem key={city} value={city}>
                                        {city}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={2}>
                        <FormControl
                            sx={{
                                margin: "0 3rem",
                                display: { xs: "none", md: "flex" },
                            }}
                        >
                            <FormLabel
                                sx={{ fontSize: "2rem" }}
                                id="cities-label"
                            >
                                City
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="cities-label"
                                name="cities"
                                defaultValue="All"
                                onChange={handleCityChange}
                            >
                                {cities &&
                                    cities.map((city) => {
                                        return (
                                            <FormControlLabel
                                                key={city}
                                                value={city}
                                                control={<Radio />}
                                                label={city}
                                            />
                                        );
                                    })}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <div className="gallery">
                            {photos &&
                                photos.map((photo, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="modal-image-container"
                                        >
                                            <ModalImage
                                                className="gallery-photo"
                                                small={photo.data().URL}
                                                medium={photo.data().URL}
                                                alt={photo.data().comment}
                                            />

                                            <Typography
                                                // variant="p"
                                                sx={{
                                                    color: "var(--fc-light)",
                                                    fontSize: ".9em",
                                                    lineHeight: "1.2em",
                                                    padding: "0 .5em",
                                                    fontstyle: "italic",
                                                }}
                                            >
                                                {photo.data().comment}
                                            </Typography>

                                            {admin && (
                                                <UpdatePhotoComment
                                                    photo={photo}
                                                    updateCounter={
                                                        updateCounter
                                                    }
                                                    setUpdateCounter={
                                                        setUpdateCounter
                                                    }
                                                />
                                            )}
                                            {admin && (
                                                <DeletePhoto
                                                    updateCounter={
                                                        updateCounter
                                                    }
                                                    setUpdateCounter={
                                                        setUpdateCounter
                                                    }
                                                    photo={photo}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    </Grid>
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        onClick={loadMorePhotos}
                        sx={{ margin: "2rem" }}
                    >
                        Next Page
                    </Button>
                </Box>
                <Divider variant="middle" />

                {videos &&
                    videos.map((video, index) => {
                        return (
                            <div key={index} className="video-container">
                                <iframe
                                    className="video"
                                    width="853"
                                    height="480"
                                    src={video}
                                    frameBorder="0"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div>
                        );
                    })}
                {admin && <Typography>{updateCounter}</Typography>}
                <Typography variant="h2" sx={{ margin: "2rem" }}>
                    Videos and Useful Links
                </Typography>

                <Container>
                    <ul>
                        <li>
                            <a
                                href="https://firebasestorage.googleapis.com/v0/b/roadtrip-96db2.appspot.com/o/videos%2Fkennedy's_disease_short_promo_v3%20(540p).mp4?alt=media&token=8059c0cb-0d4b-4627-9350-874414526c8a"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="h6" gutterBottom>
                                    What is Kennedy's Disease?
                                </Typography>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/watch?v=pig06YJijQ8&t=3s&ab_channel=DarrenArthur"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="h6" gutterBottom>
                                    How to Drive the Citroen 2cv
                                </Typography>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/watch?v=P-5qZ00mQ6I"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="h6" gutterBottom>
                                    News Clip 1
                                </Typography>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/watch?v=ddRRpac2HFI"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="h6" gutterBottom>
                                    News Clip 2
                                </Typography>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://firebasestorage.googleapis.com/v0/b/roadtrip-96db2.appspot.com/o/videos%2FWPVIKD_051822.mp4?alt=media&token=a8db90df-7ee3-43d1-aae7-ebc4efaf2248"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="h6" gutterBottom>
                                    News Clip 3
                                </Typography>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://nihrecord.nih.gov/2022/06/10/great-road-trip-visits-raise-awareness-kennedy-s-disease"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="h6" gutterBottom>
                                    NIH Record Story
                                </Typography>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://francuskie.pl/citroen-2cv-i-podroz-z-los-angeles-do-rzymu-w-szczytnym-celu-the-great-road-trip/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="h6" gutterBottom>
                                    News Story (Polish)
                                </Typography>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://youtu.be/5dpL4YSWVUw"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography variant="h6" gutterBottom>
                                    Parade of Citroens 2CVs in Castel Gandolfo,
                                    Italy
                                </Typography>
                            </a>
                        </li>
                    </ul>
                </Container>
            </div>
        </Container>
    );
};
export default Photos;
