import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteDoc, doc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../firebase";

export default function AlertDialog({ updateId, updates, setUpdates }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleNo = () => {
        setOpen(false);
    };

    const handleYes = async () => {
        setOpen(false);
        await deleteDoc(doc(db, "updates", updateId));
        const newUpdates = updates.filter((update) => update.id !== updateId);
        setUpdates(newUpdates);
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Delete
            </Button>
            <Dialog
                open={open}
                onClose={handleNo}
                aria-labelledby="delete-update"
                aria-describedby="delete-update-description"
            >
                <DialogTitle id="delete-update">
                    {"Delete this update permanently?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-update-description">
                        This action cannot be undone
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNo}>No</Button>
                    <Button onClick={handleYes}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
