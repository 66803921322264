import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { Typography } from "@mui/material";
const Layout = () => {
    return (
        <>
            {/* <Typography
                sx={{
                    textAlign: "center",
                    backgroundColor: "rgb(255, 255, 109)",
                    padding: ".25em",
                }}
            >
                This website is under construction
            </Typography> */}
            <ResponsiveAppBar />

            <Outlet />
            <Footer />
        </>
    );
};

export default Layout;
