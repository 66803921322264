import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import AchesonLogo from "../assets/AchesonLogo.png";
import SNRG from "../assets/SNRG.jpg";
import placesforpeople from "../assets/placesforpeople.jpg";
import Moray from "../assets/Moray.jpg";
import Pentreath from "../assets/Pentreath.jpg";
import leon from "../assets/leon.jpg";
import HTA from "../assets/HTA.jpeg";
import Cornwall from "../assets/Cornwall.jpeg";
import annjiLogo from "../assets/aanjiLogo.jpg";
import nido from "../assets/nido.jpg";
import shindelRock from "../assets/shindelRock.jpg";
import { Grid } from "@mui/material";

export default function MasonryImageList() {
    return (
        <Box>
            <Grid container spacing={6}>
                {sponsorLogos.map((item, index) => {
                    return (
                        <Grid
                            item
                            key={index}
                            xs={12}
                            sm={4}
                            md={3}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                className="sponsorlogo"
                                src={item}
                                // src={`${item}?w=248&fit=crop&auto=format`}
                                // srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                // srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={item}
                                loading="lazy"
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}

const sponsorLogos = [
    shindelRock,
    nido,
    annjiLogo,
    AchesonLogo,
    Moray,
    SNRG,
    HTA,
    Pentreath,
    Cornwall,
    placesforpeople,
    leon,
];
