import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../firebase";

export default function AlertDialog({
    update,
    updateId,
    updates,
    setUpdates,
    content,
}) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleNo = () => {
        setOpen(false);
    };

    const handleYes = async () => {
        setOpen(false);

        await updateDoc(doc(db, "updates", update.id), {
            content: content,
        });

        // await deleteDoc(doc(db, "updates", id));
        // const newUpdates = updates.filter((update) => update.id !== id);
        // setUpdates(newUpdates);
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Update
            </Button>
            <Dialog
                open={open}
                onClose={handleNo}
                aria-labelledby="update-update"
                aria-describedby="update-update-description"
            >
                <DialogTitle id="update-update">
                    {"Update this update permanently?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="update-update-description">
                        Are you sure you want to update this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNo}>No</Button>
                    <Button onClick={handleYes}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
