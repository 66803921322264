import { Container } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <Container>
                <h4>The Great Road Trip 2022</h4>
                <div className="footer-links-container">
                    <div className="footer-links">
                        <Link to="photos">Photos and Videos</Link>
                        <Link to="news">News and Updates</Link>
                        <Link to="volunteer">Sign Up</Link>
                        <a
                            href="https://www.kennedysdisease.org/kda-campaigns"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Donate
                        </a>
                    </div>
                    <div className="footer-links">
                        <a
                            href="https://the2cvshop.co.uk/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            The 2cv Shop
                        </a>
                        <a
                            href="https://www.kennedysdisease.org/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Kennedy's Disease Association
                        </a>
                        <a href="#header">Back to Top</a>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
