import { Container, Typography, Divider } from "@mui/material";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";

const News = () => {
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        async function getUpdates() {
            // const querySnapshot = await getDocs(collection(db, "updates"));
            const q = query(
                collection(db, "updates"),
                orderBy("createdAt", "desc"),
                limit(30)
            );
            const querySnapshot = await getDocs(q);
            let newUpdates = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                newUpdates = [...newUpdates, doc];
                setUpdates(newUpdates);
            });
        }

        getUpdates();
    }, []);

    return (
        <Container maxWidth="sm">
            <div className="news-container">
                <Typography variant="h2">News and Updates</Typography>
                {updates.map((update) => {
                    return (
                        <div className="news-item" key={update.id}>
                            <Typography
                                sx={{
                                    fontSize: ".85em",
                                    fontWeight: "bold",
                                }}
                            >
                                {update.data().date}
                            </Typography>
                            <Typography
                                sx={{
                                    lineHeight: "1.3em",
                                    padding: ".5em",
                                }}
                            >
                                {update.data().content}
                            </Typography>
                            <Divider variant="middle" />
                        </div>
                    );
                })}
            </div>
        </Container>
    );
};

export default News;
