import {
    Button,
    Typography,
    Container,
    Divider,
    Card,
    Grid,
    Box,
} from "@mui/material";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";

import infoImage from "../assets/fancyCar.jpg";
import tripLogo from "../assets/tripLogo.jpg";
import React, { useState, useEffect } from "react";
import americanItinerary from "../assets/americanItinerary.png";
import europeanItinerary from "../assets/europeanItinerary.png";
import susan from "../assets/susan.png";
import USAMap from "../assets/USAMap.png";
import EuropeMap from "../assets/EuropeMap.png";
import legs from "../assets/Legs.png";
import legsUK from "../assets/LegsUK.png";
// import EuropeItinerary from "../assets/EuropeItinerary.jpg";
import heroImage from "../assets/GallupCar.jpg";
import { Link } from "react-router-dom";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage, db } from "../firebase";
import ModalImage from "react-modal-image";

//sponsor logos

import MasonryImageList from "../components/MasonryImageList";

const Home = () => {
    const [photos, setPhotos] = useState([]);
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        async function getPhotos() {
            const listRef = ref(storage, "photos");

            // Find all the prefixes and items.
            listAll(listRef)
                .then((res) => {
                    let newPhotos = [];
                    res.items.forEach((itemRef) => {
                        getDownloadURL(itemRef).then((url) => {
                            newPhotos = [...newPhotos, url];
                            setPhotos(newPhotos);

                            // console.log(newPhotos);
                        });
                    });
                    // console.log(newPhotos);
                })
                .catch((error) => {
                    // Uh-oh, an error occurred!
                });
        }
        getPhotos();
    }, []);

    useEffect(() => {
        async function getUpdates() {
            // const querySnapshot = await getDocs(collection(db, "updates"));
            const q = query(
                collection(db, "updates"),
                orderBy("createdAt", "desc"),
                limit(30)
            );
            const querySnapshot = await getDocs(q);
            let newUpdates = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                newUpdates = [...newUpdates, doc];
                setUpdates(newUpdates);
            });
        }

        getUpdates();
    }, []);

    return (
        <div className="home" id="home">
            <Box
                sx={{
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
  url(${heroImage})`,
                    backgroundSize: "cover",
                    padding: "10vw 0",
                    marginBottom: "3rem",
                }}
            >
                <Container>
                    <Typography
                        variant="h1"
                        sx={{
                            color: "#FFFFFF",
                            fontSize: "clamp(3rem, 6vw, 10.5rem)",
                            fontWeight: "bold",
                            maxWidth: "45rem",
                            textShadow: "0 0 25px #000000",
                        }}
                    >
                        The Great Road Trip 2022
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#e0e0e0",
                            textShadow: "0 0 25px #000000",
                        }}
                    >
                        April to July, Los Angeles to Rome
                    </Typography>
                </Container>
            </Box>

            <Container maxWidth="xl">
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: "60ch",
                        }}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: "bold",
                                marginBottom: ".25em",
                                lineHeight: "1em",
                                textAlign: "center",
                            }}
                        >
                            Two Continents, 6600 Miles
                        </Typography>
                        <Typography
                            sx={{ maxWidth: "75ch", marginBottom: "1em" }}
                        >
                            The Great Road Trip is a transcontinental journey in
                            a refurbished 35-year-old, 29-horsepower Citroen 2CV
                            from Los Angeles to Rome, that will take place from
                            April to July 2022. The total distance driven will
                            be 4,300 miles in the USA and Canada and 2,300 miles
                            in Europe.
                        </Typography>
                        <Typography
                            sx={{ maxWidth: "75ch", marginBottom: "1em" }}
                        >
                            The Citroen 2CV, affectionately known as the "Tin
                            Snail", was manufactured from 1948 to 1990 and is a
                            cult car in Europe.
                        </Typography>
                        <Typography
                            sx={{ maxWidth: "75ch", marginBottom: "1em" }}
                        >
                            Volunteer drivers and passengers, including members
                            of the KD community and Citroen car clubs across the
                            United States, will join us on the road to a cure.
                            The trip includes media events and fundraising
                            dinners. If you would like to volunteer, please
                            follow the link at the top of the page. It will be a
                            truly epic journey that you do not want to miss!
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "0 1em",
                            }}
                        >
                            <Typography sx={{ fontWeight: "bold" }}>
                                Donations are welcome. Sponsorships are
                                available.
                            </Typography>

                            <Button
                                href="https://www.kennedysdisease.org/kda-campaigns"
                                target="_blank"
                                rel="noreferrer"
                                variant="contained"
                                color="secondary"
                                size="large"
                            >
                                Donate
                            </Button>
                        </Box>
                    </Box>
                    <Box>
                        <img
                            style={{ width: "100%", height: "auto" }}
                            src={tripLogo}
                            alt="Great Road Trip Logo"
                        />
                    </Box>
                </Box>
                <Divider variant="middle" />
            </Container>

            <Container>
                <Grid
                    container
                    spacing={6}
                    sx={{ marginTop: "0", marginBottom: "3rem" }}
                >
                    <Grid item xs={12} md={6}>
                        <img
                            src={USAMap}
                            alt="usa route"
                            style={{ width: "100%", height: "auto" }}
                        />
                        <img
                            src={legs}
                            alt="usa route"
                            style={{
                                width: "100%",
                                height: "auto",
                                padding: "0 5rem",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <img
                                src={EuropeMap}
                                alt="Europe route"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                }}
                            />
                            <img
                                src={legsUK}
                                alt="Europe route"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    padding: "0 5rem",
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <div className="bg-blue">
                <Container>
                    <h2 className="updates-headline">Latest News</h2>
                    <div className="updates">
                        <Card>
                            <div className="latest-news">
                                {updates.slice(0, 4).map((update, index) => {
                                    return (
                                        <div key={index}>
                                            <Typography
                                                sx={{
                                                    fontSize: ".85em",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {update.data().date}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    lineHeight: "1.3em",
                                                    padding: "0 .5em",
                                                }}
                                            >
                                                {update
                                                    .data()
                                                    .content.slice(0, 200)
                                                    .trim() + "..."}
                                            </Typography>
                                            <Divider variant="middle" />
                                        </div>
                                    );
                                })}
                                <Link to="news">
                                    <Typography sx={{ textAlign: "end" }}>
                                        Read More...
                                    </Typography>
                                </Link>
                            </div>
                        </Card>
                        <div className="latest-photos">
                            <div className="modal-image-container">
                                <ModalImage
                                    className="latest-photo-main"
                                    small={susan}
                                    medium={susan}
                                    alt=""
                                />
                            </div>

                            <div>
                                <div>
                                    <Link to="photos">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                        >
                                            Gallery
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Container>
                <div className="info-kd">
                    <img src={infoImage} alt="" />
                    <div>
                        <h3>What is Kennedy's Disease?</h3>
                        <Typography>
                            Kennedy's Disease (KD), or Spinal and Bulbar
                            Muscular Atrophy (SBMA), is an inherited,
                            adult-onset neuromuscular disease that affects
                            primarily men. KD causes progressive muscular
                            weakness, especially noticeable in the legs, arms,
                            and facial regions. This results in deteriorating
                            ability walk, speak, swallow, and perform other
                            basic life functions. The muscle weakness can result
                            in aspiration pneumonia and falls that can lead to
                            serious complications and death.
                        </Typography>
                        <h3>Can Kennedy's Disease be Cured?</h3>

                        <Typography>
                            While there is currently no cure or treatment for
                            KD, research has suggested several possible pathways
                            that may lead to a treatment. Research continues and
                            it needs your support. The purpose of{" "}
                            <strong>The Great Road Trip</strong> is to raise
                            awareness of Kennedy's Disease and to raise
                            much-needed funds for the US and European research
                            programs.
                        </Typography>

                        <Button
                            href="https://www.kennedysdisease.org/what-is-kd/what-is-kennedys-disease"
                            target="_blank"
                            rel="noreferrer"
                            variant="contained"
                            color="secondary"
                            size="large"
                        >
                            Learn More
                        </Button>
                    </div>
                </div>
                <Divider variant="middle" />
            </Container>

            <Container className="sponsors">
                <h2>Our Sponsors</h2>
                <MasonryImageList />
            </Container>
        </div>
    );
};

export default Home;
